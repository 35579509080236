
import * as Sentry from '@sentry/browser';



Sentry.init({
  dsn: "https://2167231570d74901b73bddb0cebf0be8@sentry.io/6536",
  
  release: 'd5d49c915b7b64993f14dc79d7f1648efdb96f39',
  
  linesOfContext: 500, 
  autoSessionTracking: false,
  environment: 'production',
  ignoreErrors: [ 'DOMException: WRONG_DOCUMENT_ERR', 'Non-Error promise rejection captured', 'EvalError' ]
});


window.Sentry = Sentry

